var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"flex-column white",staticStyle:{"min-height":"100%"},attrs:{"max-width":""}},[_c('div',{staticClass:"flex-y-center flex-justify-between px-2",staticStyle:{"border-bottom":"1px solid #e8e8e8"}},[_c('v-tabs',{on:{"change":_vm.tabChange},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#0"}},[_vm._v("药物临床试验登记与信息公示平台")])],1)],1),_c('div',{staticClass:" flex-1 flex-column px-12 py-6"},[_c('div',{staticClass:" flex-y-center mb-2"},[_c('span',{staticClass:" flex-shrink-0"},[_vm._v("数据同步：")]),_c('form-item',{staticClass:"mr-0",attrs:{"options":[
          {
            text:'是',
            value:'1'
          },
          {
            text:'否',
            value:'0'
          }],"type":"radio"},on:{"select":function (e){_vm.data.radio = e.val,_vm.data.checkbox=[]},"cleanVal":function (e){_vm.data.radio = e.val,_vm.data.checkbox=[]}},model:{value:(_vm.data.radio),callback:function ($$v) {_vm.$set(_vm.data, "radio", $$v)},expression:"data.radio"}})],1),(_vm.data.radio == '1')?[_c('span',{staticClass:"mb-1"},[_vm._v("服务协议：")]),_c('div',{staticClass:"msg pa-2"}),_c('form-item',{staticClass:"mr-0",attrs:{"options":[
          {
            text:'我已阅读并接受以上服务协议',
            value:'1'
          }],"type":"checkbox"},model:{value:(_vm.data.checkbox),callback:function ($$v) {_vm.$set(_vm.data, "checkbox", $$v)},expression:"data.checkbox"}})]:_vm._e(),_c('div',{staticClass:" flex-center mt-2"},[_c('v-btn',{attrs:{"loading":_vm.btnLoading,"disabled":!_vm.data.radio || ((_vm.data.radio==='1')&&!_vm.data.checkbox.length),"width":"100","height":"32","depressed":"","color":"primary"},on:{"click":_vm.submit}},[_vm._v("确认")])],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }