<template>
  <v-layout max-width class="flex-column white" style="min-height:100%;">
    <div class="flex-y-center flex-justify-between px-2" style="border-bottom:1px solid #e8e8e8">
      <v-tabs v-model="tab" @change="tabChange">
        <v-tab :href="`#0`">药物临床试验登记与信息公示平台</v-tab>
      </v-tabs>
    </div>
    <div class=" flex-1 flex-column px-12 py-6" style="">
      <div class=" flex-y-center mb-2">
        <span class=" flex-shrink-0">数据同步：</span>
        <form-item
          v-model="data.radio"
          class="mr-0"
          :options="[
            {
              text:'是',
              value:'1'
            },
            {
              text:'否',
              value:'0'
            }]"
          type="radio"
          @select="(e)=>{data.radio = e.val,data.checkbox=[]}"
          @cleanVal="(e)=>{data.radio = e.val,data.checkbox=[]}"
        />
      </div>
      <template v-if="data.radio == '1'">
        <span class="mb-1">服务协议：</span>
        <div class="msg pa-2" />
        <form-item
          v-model="data.checkbox"
          class="mr-0"
          :options="[
            {
              text:'我已阅读并接受以上服务协议',
              value:'1'
            }]"
          type="checkbox"
        />
      </template>
      <div class=" flex-center mt-2">
        <!-- !data.radio || ((data.radio==='1')&&!data.checkbox.length) -->
        <!-- <v-btn :disabled="(data.radio==='1')&&!data.checkbox.length" width="100" depressed color="primary" @click="submit">确认</v-btn> -->
        <v-btn :loading="btnLoading" :disabled="!data.radio || ((data.radio==='1')&&!data.checkbox.length)" width="100" height="32" depressed color="primary" @click="submit">确认</v-btn>
      </div>
    </div>

  </v-layout>
</template>

<script>
export default {

  data() {
    return {
      tab: '0',
      btnLoading: false,
      data: {
        radio: '1',
        checkbox: []
      }
    }
  },
  mounted() {
    this.getAssociation()
  },
  methods: {
    getAssociation() {
      // this.listLoading = true
      this.$http.get(this.$api.settingGetAssociation, { data: null }).then(res => {
        // this.listLoading = false
        if (res.result) {
          this.$set(this.data, 'radio', res.result)
          if (res.result === '1') this.$set(this.data, 'checkbox', ['1'])
        }
      }).catch(() => {
        // this.listLoading = false
      })
    },
    // 切换tab
    tabChange() {
    },
    // 确认
    submit() {
      this.btnLoading = true
      const param = new URLSearchParams()
      param.append('flag', this.data.radio || '')
      this.$http.post(this.$api.settingSaveAssociation, { data: param }).then(res => {
        this.btnLoading = false
        this.$message.success(res.message)
      }).catch(() => {
        this.btnLoading = false
        this.getAssociation()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-title{
    width:130px;
    color: #777;
}
.msg{
  height: 400px;
  border: 1px solid #ccc;
  overflow: auto;
}
</style>
